import React from "react";
import head from "../../assets/Images/Head.png";

function Topdiv() {
  return (
    <div className="row height" >
      <div className="col-12">
        <img src={head} border="0" className="responsive-image" alt="Null" />
      </div>
    </div>
  );
}
export default Topdiv;
