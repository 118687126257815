const initialStateRadio = { radiovalue: "mouseHover" } 
const checkAlign = { check:true }
const displayValue = { number : {tabName: "", number : 20, error : "" }}
const sura = { sura: { index: 1, s1: "1",sura: "سورة الفاتحة" }} ;
const FFamily = {ff : {s2:"sans-serif",s1: 301,n100: 301 }};
const Sura_Aya = { SuraAya : { aya: 1, surah: 1}} ;
const Sura_Data = { } ;
const Languages = { Languages: { s1:1,s2:"English",s3:1,Combo: "Translation",n100: 1 }} ;
const TranslatorName = { Translator: { s1:"en_ahmedali",s2:"Ahmed Ali",s4:"L"}} ;
  // All Reducers:
//Radio Button For Translation Tab
 function radioReducer(state = initialStateRadio, action) {
    if (action.type === 'Fixed') {
        return {
          ...state,
          radiovalue: action.payload.radio
          }
      }
    else return state;
  }
// Input value font size for dispaly Tab
  function displayOptButtonReducer(state = displayValue, action) {
    if (action.type === 'Error') {
        return {
          ...state,
          number: action.payload
          }
      }
      else return state;
  }
  // check box for text align
  function displayOptCheckReducer(state = checkAlign, action) {
    if (action.type === 'Check') {
        return {
          ...state,
          check: action.payload
          }
      }
      else return state;
  }

  function suraCall(state = sura, action) {
    if (action.type === 'Sura') {
        return { ...state, sura: action.payload}
      }
      else return state;
  }

  function selectedAyaSurah(state = Sura_Aya, action) {
    if (action.type === 'SuraAya') {
        return { ...state, SuraAya: action.payload}
      }
      else return state;
  }
  function SurahData(state = Sura_Data, action) {
    if (action.type === 'Sura_Data') {
        return { ...state, SuraAya: action.payload}
      }
      else return state;
  }
  function TotalSuraData(state = Sura_Data, action) {
    if (action.type === 'totalsura') {
        return { ...state, SuraAya: action.payload}
      }
      else return state;
  }
  function LanguageData(state = Languages, action) {
    if (action.type === 'language') {
        return { ...state, Languages: action.payload}
      }
      else return state;
  }
  function Translator (state = TranslatorName, action) {
    if (action.type === 'translator') {
        return { ...state, Translator: action.payload}
      }
      else return state;
  }
  function F_Family (state = FFamily, action) {
    if (action.type === 'FontFamily') {
        return { ...state, ff: action.payload}
      }
      else return state;
  }
  export {radioReducer,displayOptButtonReducer,displayOptCheckReducer,
  TotalSuraData,suraCall,selectedAyaSurah,SurahData,LanguageData,Translator,F_Family};