import { createStore,combineReducers } from "redux";
import {radioReducer,displayOptButtonReducer,TotalSuraData,displayOptCheckReducer,
    suraCall,selectedAyaSurah,SurahData,LanguageData,Translator,F_Family} from "../reducers/index";

const reducers = combineReducers({
    radioReducer,
    displayOptButtonReducer,
    displayOptCheckReducer,
    suraCall,
    selectedAyaSurah,SurahData,
    LanguageData,F_Family,
    Translator,TotalSuraData
})

const store = createStore(reducers);
export default store;
