import {React,useState} from "react";
import ReactAudioPlayer from 'react-audio-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle,faMinusCircle,faSearch,faWrench,faChevronCircleDown,faChevronCircleUp} from '@fortawesome/free-solid-svg-icons';
import {Tabs,Tab,Button,Accordion,Card,Form,Col,Row,Nav} from 'react-bootstrap';
import { connect,useSelector,useDispatch} from 'react-redux';
import {Radio,displayoption,AlignCheck,SelectCombo,LanguagesData,TranslatorData,SuraAya,FontFamily} from '../../storeJS/action/index';
import Combo from '../Component/ListCombo';


function  LeftMain (props) {
const [state, setstate] = useState()
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(false); ///Check Box Display Setting 
    const TransRadioButton = useSelector((state) => state.radioReducer.radiovalue); //Trans Tab Radio Button
    const sizeButton =  useSelector((state) => state.displayOptButtonReducer.number.number); //Trans Tab Radio Button
    const [error, setError] = useState(""); //Error msg for Trans Tab Font Size
    const [text, setText] = useState(sizeButton); //Value for Trans Tab Font Size
  
    const displayOptions = { 
        tabName: props.name,
        number : text,
        error : error
        }
    function inputonchange(event){
        const inputvalue  = event.target.value;
        setText(inputvalue);
    }
    function handleOnChange(event) {  //Radio Button change for Trans Tab
        const radiovalues  = event.target.value;
        const Tab = { 
        tabname: props.name,
        radio : radiovalues
        }
        dispatch(Radio(Tab));
    }
      function Increment() {  //Increment button For font size in Dipslay/Browse Tab
        if (error.trim() !== "") setError("");
            setText(parseInt(text)+1);
            dispatch(displayoption(displayOptions));
        }
      function Decrement() { //Decrement button For font size in Dipslay/Browse Tab
        if (parseInt(text) > 1 ) {
            setText(parseInt(text)-1);
            dispatch(displayoption(displayOptions));
        }
        else{
            setError("Page Cannot less then zero!"); 
            dispatch(displayoption(displayOptions));
            return;
        }
    }
    // function SetDefault(e){
    //     e.preventDefault();
    //     console.log("Clicked!!!");
    // }
    function checkAlign(e) {
        setChecked(!checked);
        dispatch(AlignCheck(checked));
    }
    function handleSelectChange(event) { 
        if(event.Combo === 'Translation') 
        {
            dispatch(LanguagesData(event))
        }
        else if(event.Combo === 'Aya') 
        {
            let select = {
                aya: event.s2,
                surah: event.surah
            }
            dispatch(SuraAya(select))
        }
        else if(event.Combo === 'Translator') {
            dispatch(TranslatorData(event))
        }
        else if(event.Combo === 'Size') {
            const display = { 
                tabName: event.Combo,
                number : event.px,
                error : error
                }
            dispatch(displayoption(display));
        }
        else if(event.Combo === 'Font') {
             dispatch(FontFamily(event));
        }
        else{
            dispatch(SelectCombo(event))
        }
            }
        let body,head;
        let Recitationcombo = {marginTop: "2px",marginLeft:"4px"};
        let Search_rootcombo = {marginTop:"23px"};
        if(props.name === 'Search'){
            head =  <div>{props.name}<FontAwesomeIcon style={{float:`right`, fontSize:`12px`}} icon={state === 0 ? faChevronCircleDown : faChevronCircleUp}/> </div> ;
            body = <div className="mb-2">
                    <Tabs defaultActiveKey="quran" style={{background:`#E5E7E9 `,padding:0}} className="TabCover" id="uncontrolled-tab-example">
                    <Tab  eventKey="quran" title="Quran" style={{ padding: `6px 3px 9px 0px`, margin: `0px 2px 0px -6px`}}>
                      <Row>
                      <input style={{ marginTop: `22px`,width:`290px`,marginLeft: `auto`}} className="" type="text"></input>
                     <Button size="sm" className="btnsearch" variant="primary"><FontAwesomeIcon icon={ faSearch}/></Button>
                      
                      </Row >
                      <Row style={{float:`right`}}><Nav.Link style={{ margin: `0px`,padding: `0px`}} href="#link">Tips</Nav.Link></Row>
                    </Tab>
                    <Tab eventKey="roots" title="Roots">
                    <Row>
                      <Col lg={9}><Combo size="sm" stylesheet={Search_rootcombo} onSelectChange={handleSelectChange} tab="Search"></Combo></Col>
                      <Col lg={3}><Button size="sm" className="root" variant="primary"><FontAwesomeIcon icon={ faSearch}/></Button></Col>
                      </Row >
                      {/* <Row style={{marginTop: `-17px`}}>
                      <div class="menu-row" id="charList"><a class="" href="javascript:initRootMenu('آ')">آ</a> <a class="" href="javascript:initRootMenu('ا')">ا</a> <a class="" href="javascript:initRootMenu('ب')">ب</a> <a class="" href="javascript:initRootMenu('ت')">ت</a> <a class="" href="javascript:initRootMenu('ث')">ث</a> <a class="" href="javascript:initRootMenu('ج')">ج</a> <a class="" href="javascript:initRootMenu('ح')">ح</a> <a class="" href="javascript:initRootMenu('خ')">خ</a> <a class="" href="javascript:initRootMenu('د')">د</a> <a class="" href="javascript:initRootMenu('ذ')">ذ</a> <a class="" href="javascript:initRootMenu('ر')">ر</a> <a class="" href="javascript:initRootMenu('ز')">ز</a> <a class="" href="javascript:initRootMenu('س')">س</a> <a class="current-char" href="javascript:initRootMenu('ش')">ش</a> <a class="" href="javascript:initRootMenu('ص')">ص</a> <a class="" href="javascript:initRootMenu('ض')">ض</a> <a class="" href="javascript:initRootMenu('ط')">ط</a> <a class="" href="javascript:initRootMenu('ظ')">ظ</a> <a class="" href="javascript:initRootMenu('ع')">ع</a> <a class="" href="javascript:initRootMenu('غ')">غ</a> <a class="" href="javascript:initRootMenu('ف')">ف</a> <a class="" href="javascript:initRootMenu('ق')">ق</a> <a class="" href="javascript:initRootMenu('ك')">ك</a> <a class="" href="javascript:initRootMenu('ل')">ل</a> <a class="" href="javascript:initRootMenu('م')">م</a> <a class="" href="javascript:initRootMenu('ن')">ن</a> <a class="" href="javascript:initRootMenu('ه')">ه</a> <a class="" href="javascript:initRootMenu('و')">و</a> <a class="" href="javascript:initRootMenu('ي')">ي</a> </div>
                      </Row> */}
                    </Tab>
                    <Tab  eventKey="goto" title="Goto" style={{ padding: `6px 3px 9px 0px`, margin: `0px 2px 0px -6px`}}>
                    <Row>
                      <input style={{ marginTop: `22px`,width:`290px`,marginLeft: `auto`}} className="" type="text"></input>
                      <Button size="sm" className="btnsearch" variant="primary"><FontAwesomeIcon icon={ faSearch}/></Button>
                      </Row >
                      <Row style={{float:`right`}}><Nav.Link style={{ margin: `0px`,padding: `0px`}} href="#link">Tips</Nav.Link></Row>
                    </Tab>
                    </Tabs>
            </div>
        }
        if(props.name === 'Browse'){
            head =  <div> {props.name} <FontAwesomeIcon style={{float:`right`, fontSize:`12px`}} icon={state === 0 ? faChevronCircleDown: faChevronCircleUp  }/>   </div> ;
            body = <div>
                <Row>
                    <Col><Combo  name="Sura:" key={"Sura"} onSelectChange={handleSelectChange} id= "Sura" tab="Browser" size="sm"></Combo></Col>
                </Row>
                <Row>
                    <Col><Combo name="Aya:"  key={"Aya"} onSelectChange={handleSelectChange} size="sm"  id= "Aya" tab="Browser" ></Combo></Col>
                </Row> 
                {/* <Row>
                    <Col> <Combo name="Juzz:" key={"Juzz"} onSelectChange={handleSelectChange} tab="Browser"  id= "Juzz" size="sm"></Combo></Col>
                </Row> */}
                {/* <Row>
                    <Col lg={2}  style={{padding:`0px`, marginTop:`2px`}}><Form.Label>Page:</Form.Label></Col>           
                    <Col lg={10} style={{padding:`0px`}}> <input style={{width:`68px`}}  placeholder={("Page No.").toString()} id="Pageinput" value={text} readOnly className="mr-1" type ="text" pattern="[0-9]*"></input>
                    <Button  variant="primary" className="mr-2 cornor" onClick={Increment}  size="sm"><FontAwesomeIcon icon={ faPlusCircle}/></Button>
                    <Button  variant="primary" className="cornor" onClick={Decrement} size="sm"> <FontAwesomeIcon icon={ faMinusCircle}/></Button>           
                    </Col>
                </Row> */}
                
            </div>
            
        }
        if(props.name === 'Recitation'){
            head =  <div>
                <Row>
                <Col lg={9}> {props.name} <FontAwesomeIcon style={{float:`right`, fontSize:`12px`}}icon={state === 0 ?  faChevronCircleDown : faChevronCircleUp}/> </Col>
                <Col lg={3} className=""><FontAwesomeIcon icon={ faWrench  }/></Col></Row>
            </div>;
            body = <div className="mb-2">
                <Combo key={"Recitation"} onSelectChange={handleSelectChange} size="sm" name="Lang:" tab="Recitation" stylesheet={Recitationcombo}></Combo>
                <ReactAudioPlayer  src="my_audio_file.ogg" controls />
            </div>
        }
        if(props.name === 'Quran'){
            head =  <div> {props.name} <FontAwesomeIcon style={{float:`right`, fontSize:`12px`}} icon={state === 0 ?   faChevronCircleDown : faChevronCircleUp }/> </div> ;
            body = <div className="mb-2">
                {/* <Combo name="Text:" size="sm" tab="Quran"></Combo> */}
                <Form.Group id="formGridCheckbox">
                    <Form.Check type="checkbox" label="Show Pause Marks" />
                    <Form.Check type="checkbox" label="Show Small-Alef" />
                </Form.Group>
            </div>
        }
        if(props.name === 'Display Options'){
            head =  <div> {props.name} <FontAwesomeIcon style={{float:`right`, fontSize:`12px`}} icon={state === 0 ?   faChevronCircleDown : faChevronCircleUp }/> </div> ;
            body = <div className="mb-2">
                <Row>
                 <Col lg={12}><Combo  name="Font:" key={"Font"} onSelectChange={handleSelectChange} id= "Font" tab="Display Options" size="sm"></Combo></Col>
                {/* <Col lg={3}><Button  className="cornor" variant="primary" size="sm" onClick={SetDefault}>Default</Button></Col> */}
                </Row>
                <Row>
                <Col lg={12}><Combo  name="Size:" key={"Size"} onSelectChange={handleSelectChange} id= "Size" tab="Display Options" size="sm"></Combo></Col>
                </Row>
                <Row>
                    <Col lg={2}  style={{padding:`0px`, marginTop:`2px`}}><Form.Label>Font Size:</Form.Label></Col>   
                    <Col lg={10} style={{padding:`0px`,marginTop:`8px`}}>
                    <input style={{width:`68px`}}  placeholder={("Font Size").toString()} onChange={inputonchange} id="FontSize" value={text} className="mr-1" type ="text" pattern="[0-9]*"></input>
                    <Button  variant="primary" className="mr-2 cornor" onClick={Increment}  size="sm"><FontAwesomeIcon icon={ faPlusCircle}/></Button>
                    <Button  variant="primary" className="cornor" onClick={Decrement} size="sm"> <FontAwesomeIcon icon={ faMinusCircle}/></Button>           
                    </Col>
                </Row>
                <Row>
                <Form.Check className="mr-2"  checked={checked}  onChange={checkAlign} type="checkbox" label="Align Text" />
                </Row>
            </div>
        }
        if(props.name === 'Translation')
        {
            head =  <div>{props.name} <FontAwesomeIcon style={{float:`right`, fontSize:`12px`}} icon={state === 0 ?   faChevronCircleDown : faChevronCircleUp }/></div> ;
            body =  <div>
                 <Combo size="sm" key={"Translation"} onSelectChange={handleSelectChange} name="Translation:" id="Translation" tab="Translation" stylesheet={Recitationcombo}></Combo>
                 <Combo size="sm" key={"Translator"} onSelectChange={handleSelectChange}  name="Translator:"  id="Translator" tab="Translation" stylesheet={Recitationcombo}></Combo>
                    <Form.Group as={Row}>
                        <Col sm={12} md={12} lg={12}>
                            <Form.Check type="radio" label="Fixed Translation Box" checked={TransRadioButton === 'fixed'}  onChange={handleOnChange} value="fixed" name="Radio" id="Fixed"  /><br />
                            <Form.Check type="radio" label="Translation on Mouse over" checked={TransRadioButton === 'mouseHover'}  onChange={handleOnChange} value="mouseHover" name="Radio" id="Mouseover" /><br />
                            <Form.Check type="radio" label="None" value="none" checked={TransRadioButton === 'none'}  onChange={handleOnChange} name="Radio" id="none"/>
                        </Col>
                    </Form.Group>
                    </div>
        }

        const menubox = function (event,key) {
            if(state === 0) setstate(1)
            else setstate(0)   
        }
      return (         
            <Accordion defaultActiveKey="0" lg={12} md={12} sm={12}  className="accordianSize" style={{}} >
            <Card className="sub-menu">
                <Accordion.Toggle as={Card.Header} onClick={menubox} className="Cardhead" eventKey="0">
                    {head}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                <Card.Body>
                    {body}
                </Card.Body>
                </Accordion.Collapse>
            </Card>
            </Accordion>
      );
  }
  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  const mapDispatchToProps = (dispatch) => {
    var suraData = getCookie("Sura");
    var AyaData = getCookie("Aya");
    var Trans_Data = getCookie("Translation");
    var Translaor_Data = getCookie("Translator");
    var Size = getCookie("Size");
    var Font_Data = getCookie("Font");
    if (suraData !== "") { suraData = JSON.parse(suraData); dispatch(SelectCombo(suraData)) }
    if (Trans_Data !== "") {  Trans_Data = JSON.parse(Trans_Data);dispatch(LanguagesData(Trans_Data)) }
    if (Translaor_Data !== "") { Translaor_Data = JSON.parse(Translaor_Data); dispatch(TranslatorData(Translaor_Data)) }
    if (AyaData !== "") { AyaData = JSON.parse(AyaData); let select = { aya: AyaData.s2, surah: AyaData.surah }; dispatch(SuraAya(select)) }
    if(Font_Data !== ""){ Font_Data = JSON.parse(Font_Data); dispatch(FontFamily(Font_Data));}
    if (Size !== "") { Size = JSON.parse(Size);  
        const display = { 
        tabName: Size.Combo,
        number : Size.px,
        error : "error"
        };
         dispatch(displayoption(display));  }
    
    return {

    }
  }

  export default connect("",mapDispatchToProps)(LeftMain);

