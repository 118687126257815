import axios from 'axios';

const http = axios.create({
  // baseURL: 'http://192.168.1.108:100/Quran/api', //Release Base URL
  baseURL:'http://pearlerp.com:100/Quran/api',
  headers: {'Content-Type': 'application/json'},
});

http.interceptors.request.use(
  async config => { config.headers = { }; return config; },
  err => { return Promise.reject(err); },
);

export default http;

// import useSWR from 'swr'
// const fetcher = (...args) => fetch(...args).then((res) => res.json())
// function Config() {
//   const { data, error } = useSWR('https://api.github.com/repos/vercel/swr', fetcher);

//   if (error) return <div>failed to load</div>
//   if (!data) return <div>loading...</div>
//   return <div>hello {data.name}!</div>
// }


// export default Config;