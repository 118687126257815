import { React} from "react";
import LeftMain from "./components/MainContainer/LeftMain.jsx";
import Navdiv from "./components/NavContainer/Navdiv";
import RightMain from "./components/MainContainer/RightMain";
import Top from "./components/TopContainer/Topdiv";
import { Container} from "react-bootstrap";
import { Collapse } from 'antd';
import "./App.css";

function App() {
  const { Panel } = Collapse;
  const accordian = [
   // "Search",
    "Browse",
    "Translation",
  //  "Recitation",
  //  "Quran",
    "Display Options",
  ];
  return (
    <div className="App">
      <Container fluid>
        <Top></Top>
        <Navdiv></Navdiv>
        <div className="Main">
          <div className="row">
            {/* mainLeft css class is used in acccordian tab for line partation */}
            <div className="left col-lg-3 col-md-4 col-sm-12 ">
              {accordian.map((tabs, i) => (
                <LeftMain key={i} name={tabs} ></LeftMain>
              ))}
            </div>

            <div  style={{marginTop: `10px`}} className="landscape col-lg-12 col-md-4 col-sm-12 ">
            <Collapse>
            <Panel header="Settings" key="1"  expandIconPosition='right'>
            {accordian.map((tabs, i) => (
                        <LeftMain key={i} name={tabs} ></LeftMain>
                      ))}
            </Panel>
           </Collapse>
            </div>
            <div className="col-lg-9 col-md-8 col-sm-12">
              <RightMain></RightMain>
            </div>
          </div>
          <div id="footer">Marjan Ul Quran © 2020 - 2021</div>
        </div>
      </Container>
    </div>
  );
}

export default App;
