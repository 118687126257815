import {React ,useState } from 'react';
import { Modal,Button, Navbar,Nav} from 'react-bootstrap';
import { Drawer } from 'antd';
import LeftMain from "../MainContainer/LeftMain";
import {  SettingOutlined } from '@ant-design/icons'
function Navdiv (){
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const accordian = ["Search","Browse","Translation","Quran","Display Options",];
return(
   <div className="top-menu">
      <Modal show={show} 
                onHide={handleClose} 
                backdrop="static"
                keyboard={false} 
                centered={true}>
                <Modal.Header closeButton>
                  <Modal.Title>Marjan ul Quran</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   Quran Application!!!!
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary">Understood</Button>
                </Modal.Footer>
      </Modal> 
      <Drawer
        title="Settings"
        placement="left"
        closable={true}
        width= {270}
        onClose={onClose}
        visible={visible}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
          </div>
        }
      >
        {accordian.map((tabs, i) => (
          <LeftMain key={i} name={tabs} ></LeftMain>
          ))}
      </Drawer>
      <Navbar collapseOnSelect expand="sm" bg="light" variant="light">
      <Navbar.Brand className="setting" onClick={showDrawer} style={{cursor:`pointer`}} ><SettingOutlined /></Navbar.Brand>
  <Navbar.Brand style={{cursor:`pointer`}} >Marjan-ul-Quran</Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link  onClick={handleShow} >About</Nav.Link>
      <Nav.Link  onClick={handleShow}>FAQ</Nav.Link>
      <Nav.Link  onClick={handleShow}>News</Nav.Link>
    </Nav>
    <Nav>
      <Nav.Link  onClick={handleShow}>Share</Nav.Link>
      <Nav.Link  onClick={handleShow}>Join</Nav.Link>
      <Nav.Link  onClick={handleShow}>Contact</Nav.Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>
    </div> 
);

}
export default Navdiv;


