import { React,useState,useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector,useDispatch} from 'react-redux';
import {NOofSura} from '../../storeJS/action/index'
import http from "../../apiConfig/config";


function Combo(props) {
  const [selectedCombo, setselectedCombo] = useState([])
  
  const dispatch = useDispatch();
  let surah_aya =[];
  let sura = useSelector((state) => state.SurahData.SuraAya);
  let Lang = useSelector((state) => state.LanguageData.Languages);
  var Lang_code = parseInt(Lang.s1);
  var lang_type = Lang.s2.replaceAll(/\s/g,'');
  if(props.id === "Aya" && sura) {
    sura.map(function (value, key) {
    return surah_aya.push({s2: value.aya, surah: value.sura,n100:value.index})
    });
  }
  let [quranSura, setQuranSura] = useState([]);
  const GetQuranSura = async() => {
    try {
      const _GetQuranSura = await http.get('/Sura');
      if (_GetQuranSura.status === 200) {
        setQuranSura(_GetQuranSura.data);
        dispatch(NOofSura(_GetQuranSura.data))
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  let [Languages, setLanguages] = useState([]);
  const GetLanguages = async() => {
    try {
      const _GetLanguages = await http.get('/Languages');
      if (_GetLanguages.status === 200) {
        setLanguages(_GetLanguages.data);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  let [NoOfTranslation, setNoOfTranslation] = useState([]);
  const GetNoOfTranslation = async() => {
    try {
      const _GetNoOfTranslation = await http.get('/translation?langCode='+ Lang_code);
      if (_GetNoOfTranslation.status === 200) {
        setNoOfTranslation(_GetNoOfTranslation.data);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {GetQuranSura();GetLanguages();GetNoOfTranslation();},[Lang_code,lang_type,selectedCombo]);

      let example = [
        {sura:"سورة الفاتحة",s1: 1,n100: 1 },
        {sura:"سورة البقرة",s1: 2, n100: 2},
        {sura:"سورۃ آل عمران",s1: 3, n100: 3 },
        {sura:"سورۃ النساء",s1: 4, n100: 4 },
        {sura:"سورۃ المائدۃ",s1: 5, n100: 5 },
      ]
      let Fontsize = [
        {s2:"50",s1: 100,n100: 100,px:10 },
        {s2:"100",s1: 101,n100: 101,px:20 },
        {s2:"150",s1: 102, n100: 102,px:30},
        {s2:"200",s1: 103, n100: 103,px:40 },
        {s2:"250",s1: 104, n100: 104,px:50 },
        {s2:"300",s1: 105, n100: 105,px:60 },
        {s2:"350",s1: 106, n100: 106,px:70 }
      ]
      let FF_urdu = [
        {s2:"alvi_Nastaleeq",s1: 200,n100: 200 },
        {s2:"Jameel Noori Nastaleeq",s1: 201,n100: 201 },
        {s2:"NafeesWeb",s1: 202, n100: 202}
      ]
      let FF_eng = [
        {s2:"Arial",s1: 300,n100: 300 },
        {s2:"sans-serif",s1: 301,n100: 301 },
        {s2:"Verdana",s1: 302,n100: 302 },
        {s2:"Gill Sans",s1: 303,n100: 303 },
        {s2:"Tahoma",s1: 304,n100: 304 },
        {s2:"Geneva",s1: 305,n100: 305 },
        {s2:"Haettenschweiler",s1: 306,n100: 306 },
      ]
      let FF_arab = [
        {s2:"_PDMS_Saleem_QuranFont",s1: 400,n100: 400 },
        {s2:"AlQalamQuran",s1: 401,n100: 401 },
        {s2:"noorehidayat",s1: 402, n100: 402},
        {s2:"ar-Othmani",s1: 403,n100: 403 },
        {s2:"ar-Quran1",s1: 404, n100: 404},
        {s2:"MADDINA",s1: 405,n100: 405},
        {s2:"me_quran_volt_newmet",s1: 406, n100: 406},
        {s2:"ReemKufi-Regular",s1: 407, n100: 407},
        {s2:"Al Qalam Quran Majeed",s1: 408, n100: 408},
      ]
  const genralstyle = { marginLeft: "2px" };
  const dropdown = props.tab === "Recitation" || props.tab === "Translation" || props.tab === "Search" ? props.stylesheet: genralstyle;
  let font_lang = lang_type === 'Urdu' ? FF_urdu : lang_type === 'English' ?  FF_eng : FF_arab ;

    
    const ComboData =  props.tab === "Browser" && props.id === "Sura" ?
    quranSura :  props.tab === "Browser" && props.id === "Aya" ?
    surah_aya : props.tab === "Translation" && props.id === "Translation" ? 
    Languages :props.tab === "Translation" && props.id === "Translator" ? 
    NoOfTranslation : props.tab === "Display Options" && props.id === "Size" ? 
    Fontsize : props.tab === "Display Options" && props.id === "Font" ? font_lang : example;

      function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      }
    function handleSelectChange(event) { 
    // dispatch(AllFontFamily(font_lang))

      var ComboData = JSON.parse(event.target.value);
      ComboData.Combo = props.id;
      setselectedCombo(ComboData);
      setCookie(ComboData.Combo,JSON.stringify(ComboData),1);
      props.onSelectChange(ComboData);
    }
    function getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
    let sel_cache;
     sel_cache =  getCookie(props.id)
    if(sel_cache !== "")
    {
      sel_cache = JSON.parse(sel_cache)
      delete sel_cache.Combo;
      sel_cache = JSON.stringify(sel_cache)
    }
return (
    <Form.Group as={Row}>
      <Col lg={2} style={{ padding: `0px` }}>
        <Form.Label>{props.name}</Form.Label>
      </Col>
      <Col lg={10} style={{ padding: `0px` }}>
        <Form.Control as="select"  size={props.size} id={props.id} value={sel_cache} style={dropdown} onChange={handleSelectChange} >
        { ComboData.map((val,index) => (
                <option key={val.n100} value={JSON.stringify(val)}>
                {props.id === 'Aya' || props.id === 'Size'  ? "" : index+1}&nbsp;&nbsp;{val.sura}&nbsp;{val.s2}
                </option>
              ))}
        </Form.Control>
      </Col>
    </Form.Group>
  );
}

export default Combo;
