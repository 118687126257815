import {React,useState, useEffect} from "react";
import { Tabs, Tab, OverlayTrigger,Popover, Container} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft,faChevronCircleRight} from "@fortawesome/free-solid-svg-icons";
import ScrollToTop from "react-scroll-to-top";
import { useSelector,useDispatch} from 'react-redux';
import {SuraAya,SurahData,SelectCombo} from './../../storeJS/action/index';
//import InfiniteScroll from "react-infinite-scroll-component";
//import store from "../../storeJS/store/index";
// import popover_Tooltip from '../Component/Popover'
import http from "./../../apiConfig/config";
// import axios from 'axios';
//import { SyncOutlined } from '@ant-design/icons';
import { Tree,Divider,Image,Empty } from 'antd';
import 'antd/dist/antd.css';



function Middiv() {


//Tree for Notes AntDesign Start
const { DirectoryTree } = Tree;
//const antIcon = <SyncOutlined style={{ fontSize: 40,color:`#007bff`}} spin />;
  // const onSelect = (keys, info) => {
  //   console.log('Trigger Select', keys, info);
  // };

  // const onExpand = (keys, info) => {
  //   console.log('Trigger Expand',keys, info);
  // };
//Tree //Tree for Notes AntDesign End

  const dispatch = useDispatch();
  const RadioButton = useSelector((state) => state.radioReducer.radiovalue);  //Display/Browse Tab Font Size Error Display
  const checkAlign =  useSelector((state) => state.displayOptCheckReducer.check);//Display check box for Align
  const sizeButton =  useSelector((state) => state.displayOptButtonReducer.number); //Trans Tab Radio Button
  let sura = useSelector((state) => state.suraCall.sura); //Sura Selection from Combo
  let seletedAyaSurah = useSelector((state) => state.selectedAyaSurah.SuraAya); //Selected Sura & Aya
  let Translator = useSelector((state) => state.Translator.Translator); //Tranlator Data
  let Totalsura = useSelector((state) => state.TotalSuraData.SuraAya);
  let ffamily = useSelector((state) => state.F_Family.ff.s2);
  let TranslatorName = Translator.s1; // Tranlator Code for  translation tab
  let suraNo = parseInt(sura.s1);  // Current sura no
  let suraName = sura.sura; // Current sura Name

  var nextItem = function (index) {
    var i = 0,
    max = Totalsura.length;
    let nextsura = {};
    for (i; i < max; i += 1) {
        if (parseInt(Totalsura[i].s1) === index) {
           nextsura = Totalsura[i + 1];
           nextsura.Combo = "Sura";
           setCookie("Sura",JSON.stringify(nextsura),1)
            return Totalsura[i + 1];
        }
    }
    return 'not found';
};

var prevItem = function (index) {
    var i = 0,
    max = Totalsura.length;
    let prevsura = {};
    for(i; i < max; i += 1) {
        if (parseInt(Totalsura[i].s1) === index) {
          prevsura = Totalsura[i - 1];
          prevsura.Combo = "Sura";
          setCookie("Sura",JSON.stringify(prevsura),1)
            return Totalsura[i - 1];
        }
    }
    return 'not found';
};
  const previousSura = () =>  {
    if(typeof prevItem(suraNo) === 'object') dispatch(SelectCombo(prevItem(suraNo)))
  }

  const nextSura = () =>  {
    if(typeof nextItem(suraNo) === 'object') dispatch(SelectCombo(nextItem(suraNo)))
  }

    // let storev =  store.getState();
    // console.log("Store Data"+storev);
  // console.log("SelectedInformation:"+seletedAyaSurah);
  let [ayaOfSura, setAyaOfSura] = useState([]);
  const GetAyaOfSura = async() => {
    try {
      const _GetAyaOfSura = await http.get('/AyaOfSura?SuraNumber='+suraNo);
      if (_GetAyaOfSura.status === 200) 
      {
        dispatch(SurahData(_GetAyaOfSura.data))
        setAyaOfSura(_GetAyaOfSura.data);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  let [transAyaOfSura, setTransAyaOfSura] = useState([]);
  const GetTransAyaOfSura = async() => {
    try {
      const _GetTransAyaOfSura = await http.get('/TransAyaOfSura?transName='+TranslatorName+'&SuraNumber='+suraNo);
      if (_GetTransAyaOfSura.status === 200) {
        setTransAyaOfSura(_GetTransAyaOfSura.data);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  let [ThemeSuraOfAya, setThemeSuraOfAya] = useState([]);
  const GetThemeSuraOfAya = async() => {
    try {
      const _GetThemeSuraOfAya = await http.get('/ThemeSuraOfAya?SuraNumber='+seletedAyaSurah.surah+'&AyaNumber='+seletedAyaSurah.aya);
      if (_GetThemeSuraOfAya.status === 200) {
        setThemeSuraOfAya(_GetThemeSuraOfAya.data.theme);
        // console.log("Theme"+ThemeSuraOfAya);
        // console.log(_GetThemeSuraOfAya);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  let [NotesSuraOfAya, setNotesSuraOfAya] = useState([]);
  const GetNotesSuraOfAya = async() => {
    try {
      const _GetNotesSuraOfAya = await http.get('/NotesSuraOfAya?SuraNumber='+seletedAyaSurah.surah+'&AyaNumber='+seletedAyaSurah.aya);
      if (_GetNotesSuraOfAya.status === 200) {
        setNotesSuraOfAya(_GetNotesSuraOfAya.data);
        // console.log("NOtes:"+NotesSuraOfAya);
        // console.log("Get_Notes:"+_GetNotesSuraOfAya);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {GetAyaOfSura(); GetTransAyaOfSura();GetThemeSuraOfAya();GetNotesSuraOfAya();},[TranslatorName,suraNo,seletedAyaSurah]);
 const [selected,setselected] = useState({surah:seletedAyaSurah.surah,aya:seletedAyaSurah.aya}); //By Default First Aya Selected
 selected.aya = seletedAyaSurah.aya;  //Hover effect on Aya combo slection
 selected.surah = seletedAyaSurah.surah;  //Hover effect on Aya combo slection
// Theme Tab Start
const Themedata = [];
ThemeSuraOfAya.forEach(e => {
  var _push  =  { title: e.theme !== null && e.theme.length > 0 ? e.theme : "Tafseel Not Found!", key: e.bullet};
  Themedata.push(_push); 
});
// Theme Tab End
let Designdata = []; // Design Tab Start
//let myData = [];
let ArabicTrans,Arabic,Trans= "";
ayaOfSura.forEach(element => {
  if(element.aya === selected.aya){
    Arabic = element.text;
  }
});
transAyaOfSura.forEach(element => {
  if(element.aya === selected.aya){
    Trans = element.text;
  }
});
ThemeSuraOfAya.forEach(e => {
  let design_code = [];
  const noImage = require(`../../assets/Images/noimage.png`);
  if (e.designcode.indexOf(',') !== -1) {
    var segments = e.designcode.split(',');
    segments.forEach(element => {
      design_code[element] = require(`../../assets/quran/${e.sura}/${element}.JPG`);
    });
}
else design_code[e.designcode] = require(`../../assets/quran/${e.sura}/${e.designcode}.JPG`); 
 var _push  =  {source: e.designcode !== null ? design_code : noImage,Dcode: e.designcode};
 Designdata.push(_push); 
});
const key = 'Dcode';
let myData = [...new Map(Designdata.map(item =>  [item[key], item])).values()];

  const ErrorImg = (keys) => {
    console.log('Massage Error:',keys);
  };
// Design Tab End

  let  ArabicArray ,tarjamaArray = [];
  tarjamaArray = transAyaOfSura;
  ArabicArray = ayaOfSura;
  let font = ffamily;
  // if(Translator.s4 === 'R' && Translator.s1.startsWith("ur") === true ) font = 'Urdufont';
  // else if (Translator.s4 === 'R' && Translator.s1.startsWith("ar") === true ) font = 'Arabicfont';
  // else font = ' Arial, Helvetica, sans-serif ,Apple Chancery, cursive'

  // let [items,setitems] = useState(
  //   {
  //   data: ArabicArray.slice(0, 10)
  //   });
  // const [hasMore,sethasMore] = useState(true);
  // const delay = ms => new Promise(res => setTimeout(res, ms));

  //       const fetchMoreData = async() => {
  //         if (items.data.length >= ArabicArray.length) {
  //           sethasMore(false);
  //           return;
  //         }
  //         await delay(800);
  //         setitems({ ...items , data: items.data.concat(ArabicArray.slice(items.data.length, items.data.length + 10))});
  //       };
  let bismilah = "بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم";
  let bis_trans_urdu = "شروع الله کا نام لے کر جو بڑا مہربان نہایت رحم والا ہے";
  let bis_trans_eng ="In the name of Allah, most benevolent, ever-merciful";
  // PopOver Tooltip Dynamic Component Start//
  const popover_Tooltip = (props) => {
    return (         
  <Popover id="popover-basic" {...props}>
  <Popover.Title as="h3">
  Translation [{props.sura}:{props.aya}]&nbsp;<span className="ayaNumber">&nbsp;﴾{Translator.s2}﴿&nbsp;</span>
    <button
      type="button" onClick={() => document.body.click()} className="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </Popover.Title>
  <Popover.Content>
  { tarjamaArray.forEach(element => {
      if(element.aya === props.aya) ArabicTrans = <p style={ Translator.s1.startsWith("ur") === true || Translator.s1.startsWith("ar") === true  ? {fontFamily:font,fontSize:`large`,textAlign:`end`} : {fontFamily:font,fontSize:`large`} }>{Translator.s2 !== 'Transliteration' ? element.text : <UnsafeComponent html={element.text} />}</p>
    })
  }
  {ArabicTrans}
  </Popover.Content>
</Popover>); }
  // PopOver Tooltip Dynamic Component End//
const pageicon = { color: `#007bff`, cursor: `pointer`, fontSize: `1rem` };
  function ayaClick(surahNumber,ayaNumber,index) {
    let select = {
      aya: ayaNumber,
      surah: surahNumber
    }
    let ComboCookie ={
      s2:ayaNumber,
      surah:surahNumber,
      n100:index,
      Combo:"Aya"
    }
    setselected({surah:surahNumber,aya:ayaNumber});
    setCookie("Aya",JSON.stringify(ComboCookie),1)
    dispatch(SuraAya(select));
  }
  // function ayaHover(surahNumber,ayaNumber) {
  //   let select = {
  //     aya: ayaNumber,
  //     surah: surahNumber
  //   }
  //   dispatch(SuraAya(select));
  // }
  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  function UnsafeComponent({ html }) {
    // Function for Re rendering if tags returns from Database. this function is specifically
    //for translator Transliteration..
    return <span dangerouslySetInnerHTML={{ __html: html }} />;
  }
  
  return (
      <Tabs style={{marginTop: `10px`}} defaultActiveKey="quran" className="TabCover" id="Tabs">
        <Tab eventKey="quran"  title="Quran">      
          {/* class add with Tab => className="homeMain" */}
          <Container fluid>
          <div className=" row  homeHeader">
            {/* <div className="col" lg={4} md={4} sm={2}>
              <span id="suraName">{ArabicArray.length> 0 ? suraName : "No Data"}</span>
            </div> */}
            <div className="col" lg={4} md={4} sm={2}>
              <FontAwesomeIcon style={pageicon} icon={faChevronCircleLeft} onClick={previousSura} />
              &nbsp;<span className="pageNumber" style={{fontFamily:font}}>{ArabicArray.length> 0 ? suraName : "No Data"}</span>&nbsp;
              <FontAwesomeIcon style={pageicon} icon={faChevronCircleRight} onClick={nextSura}  />
            </div>
            {/* <div className="col" lg={4} md={4} sm={2}>
              <span id="juzName">{ ArabicArray.length>0 ? "الجزء الاول" : "No Data"}</span>{" "}
            </div> */}
          </div>
          <div className="row middleframe">
          <ScrollToTop smooth color="rgb(0, 123, 255)"/>
            {/* <span className={checkAlign === true ? "aya-wrapper" :  "aya-wrapperAlign"}>
              {items.data.length > 0 && 
                          <div>
                          <div className="suraHeaderFrame">
                            <div className="suraHeaderText">{suraName}</div>  
                          </div><br />
                          <div className="BismilahHeader">{bismilah}</div></div> }
                          <ScrollToTop smooth color="rgb(0, 123, 255)"/>
            <InfiniteScroll dataLength={items.data.length} next={fetchMoreData} hasMore={hasMore} loader={ <h4><Spin indicator={antIcon} size="large"></Spin></h4> }
              endMessage={
                <p style={{ textAlign: "center",fontSize:20}}>
                  <b>صدق الله العظيم</b>
                </p>
              } >
                {items.data.map(function(element, i) { 
                  if (selected['surah'] === element.sura && selected['aya'] === element.aya) 
                  return(
                    <OverlayTrigger key={i.toString()}  trigger={RadioButton === 'mouseHover' ? ['hover', 'focus'] : RadioButton === 'fixed' ? 'click' : ''} placement='auto' overlay={popover_Tooltip(element)} rootClose={true}>
                    <span key={i.toString()} onClick={()=>{ayaClick(element.sura,element.aya)}} id={element.aya}  style={ sizeButton.tabName === "Display Options" ? {fontSize:sizeButton.number}: {fontSize:20} } className="ayaTextSelected">
                      {element.text}
                      <span className="ayaNumber">&nbsp;﴿{element.aya }﴾&nbsp;</span>
                    </span>
                  </OverlayTrigger>); else return(
                    <OverlayTrigger key={i.toString()} trigger={RadioButton === 'mouseHover' ? ['hover', 'focus'] : RadioButton === 'fixed' ? 'click' : ''} placement='auto' overlay={popover_Tooltip(element)} rootClose={true}>
                    <span key={i.toString()} onClick={()=>{ayaClick(element.sura,element.aya)}} id={element.aya} style={ sizeButton.tabName === "Display Options" ? {fontSize:sizeButton.number}: {fontSize:20} } className="ayaText">
                      {element.text}
                      <span className="ayaNumber">&nbsp;﴿{element.aya }﴾&nbsp;</span>
                    </span>
                  </OverlayTrigger> );
                })}
                </InfiniteScroll>
                </span> */}
            <span id="borderimg1" className={checkAlign === true ? "aya-wrapper" :  "aya-wrapperAlign"}>
              {ArabicArray.length > 0 && 
              <div>
                <div className="suraHeaderFrame">
                  <div className="suraHeaderText" >
                    {suraName}
                    </div>
                    </div><br />
                    { suraName !== 'سورۃ التوبہ' &&  <div className="BismilahHeader">{bismilah}</div> }
                      </div> 
                      }
              { ArabicArray.length > 0 ? ArabicArray.map(function(element, i) {  
                if (selected['surah'] === element.sura && selected['aya'] === element.aya) 
                return(
                  <OverlayTrigger key={i.toString()}  trigger={RadioButton === 'mouseHover' ? ['hover', 'focus'] : RadioButton === 'fixed' ? 'click' : ''} placement='auto' overlay={popover_Tooltip(element)} rootClose={true}>
                  <span key={i.toString()} onClick={()=>{ayaClick(element.sura,element.aya,element.index)}} id={element.aya}  style={ sizeButton.number ? {fontSize:sizeButton.number}: {fontSize:20} } className="ayaTextSelected">
                    {element.text}
                    <span className="ayaNumber">&nbsp;﴿{element.aya }﴾&nbsp;</span>
                  </span>
                </OverlayTrigger>); else return(
                  <OverlayTrigger key={i.toString()} trigger={RadioButton === 'mouseHover' ? ['hover', 'focus'] : RadioButton === 'fixed' ? 'click' : ''} placement='auto' overlay={popover_Tooltip(element)} rootClose={true}>
                  <span key={i.toString()} onClick={()=>{ayaClick(element.sura,element.aya,element.index)}} id={element.aya} style={  sizeButton.number ? {fontSize:sizeButton.number}: {fontSize:20} } className="ayaText">
                    {element.text}
                    <span className="ayaNumber">&nbsp;﴿{element.aya }﴾&nbsp;</span>
                  </span>
                </OverlayTrigger> );
              }) :   <Empty imageStyle={{height:50}} description={<span>Quran Data Not Found</span>}></Empty>} 
              
            </span>
          </div>
          </Container>
        </Tab>
        <Tab eventKey="translation" title="Translation" >  
        {/* class add with Tab => className="homeMain" */}
        <Container fluid>
          <div className=" row  homeHeader">
          <div className="col" lg={4} md={4} sm={2}>
              <FontAwesomeIcon style={pageicon} icon={faChevronCircleLeft} onClick={previousSura} />
              &nbsp;<span className="pageNumber" style={{fontFamily:font}}>{ArabicArray.length> 0 ? suraName : "No Data"}</span>&nbsp;
              <FontAwesomeIcon style={pageicon} icon={faChevronCircleRight} onClick={nextSura}  />
            </div>
          </div>
          <div className="row middleframe">
            <span id="borderimg1" className={Translator.s4 === 'R' ? "aya-wrapper" :  "Trans_Eng"}>
            {tarjamaArray.length > 0 && <div>
                  <div className="suraHeaderFrame">
                    <div className="suraHeaderText" style={{fontFamily:font}}>{suraName}</div>
                  </div><br />
                  {suraName !== 'سورۃ التوبہ'  &&   <div style={{fontFamily:font}} className="BismilahHeader">{TranslatorName.startsWith("en") === true ? bis_trans_eng : TranslatorName.startsWith("ur") === true ? bis_trans_urdu : bismilah}</div> }           
                  </div> }
                  <ScrollToTop smooth color="rgb(0, 123, 255)"/>
              { tarjamaArray.length> 0 ? tarjamaArray.map(function(element, i) {
                if (selected['surah'] === element.sura && selected['aya'] === element.aya) 
                return(
                  <span key={element.aya.toString()}  onClick={()=>{ayaClick(element.sura,element.aya,element.index)}} style={ sizeButton.number ? {fontSize:sizeButton.number,fontFamily:font}: {fontSize:20,fontFamily:font} } id={element.aya} className="ayaTextSelected">
                    {Translator.s2 !== 'Transliteration' ? element.text : <UnsafeComponent html={element.text} /> }
                    {Translator.s4 === 'L'? <span className="ayaNumber">&nbsp;﴾{element.aya }﴿&nbsp;</span> : <span className="ayaNumber">&nbsp;﴿{element.aya }﴾&nbsp;</span>}
                  </span>); else return(
                  <span key={element.aya.toString()}  onClick={()=>{ayaClick(element.sura,element.aya,element.index)}} style={ sizeButton.number ? {fontSize:sizeButton.number,fontFamily:font}: {fontSize:20,fontFamily:font} } id={element.aya} className="ayaText">
                    {Translator.s2 !== 'Transliteration' ? element.text : <UnsafeComponent html={element.text} /> }
                    {Translator.s4 === 'L'? <span className="ayaNumber">&nbsp;﴾{element.aya }﴿&nbsp;</span> : <span className="ayaNumber">&nbsp;﴿{element.aya }﴾&nbsp;</span>}
                  </span>);
              }):<Empty imageStyle={{height:50}} description={<span>Translation Data Not Found</span>}></Empty> }
            </span>
          </div>
          </Container>
        </Tab>
        <Tab eventKey="theme" title="Theme">
        <Container fluid>
       <div style={{marginTop:`20px`}} id="borderimg1">
        { Themedata.length > 0 && <div className="suraHeaderFrame"><div className="suraHeaderText" >{suraName}</div></div> }
        <br />
       {/* { Themedata.length > 0 && <> {Translator.s4 === 'R' ? <p className="NotesTab"  > <span className="SelectAyaSurah"> {Arabic}&nbsp;&nbsp;[ {seletedAyaSurah.aya} : {seletedAyaSurah.surah} ]</span> <br/><span  style={{fontFamily:font}} className="SelectAyaSurah"> {Translator.s2 !== 'Transliteration' ? Trans : <UnsafeComponent html={Trans} /> }&nbsp;&nbsp;[ {seletedAyaSurah.aya} : {seletedAyaSurah.surah} ]</span> </p> : <p className="NotesTab" > <span className="SelectAyaSurah"> {Arabic}&nbsp;&nbsp;[ {seletedAyaSurah.aya} : {seletedAyaSurah.surah} ]</span> <br/><span  style={{fontFamily:font}} className="SelectAyaSurah"> {Translator.s2 !== 'Transliteration' ? Trans : <UnsafeComponent html={Trans} /> }&nbsp;&nbsp;[ {seletedAyaSurah.surah} : {seletedAyaSurah.aya} ]</span> </p>} <Divider dashed /></>} */}
        {Themedata.length > 0 ? <DirectoryTree multiple  style={{fontSize:`20px`,textAlign:`end`,fontFamily:font}} treeData={Themedata} />: <Empty imageStyle={{height:50}} description={<span>No Theme Found</span>}></Empty>}
        </div> 
        </Container>
        </Tab>
        <Tab eventKey="notes" title="Notes">
        <Container fluid>
          <div style={{marginTop:`20px`}} id="borderimg1">
          { NotesSuraOfAya.length > 0 && <div className="suraHeaderFrame"><div className="suraHeaderText" >{suraName}</div></div>  }
        {/* { NotesSuraOfAya.length > 0 && <> {Translator.s4 === 'R' ? <p className="NotesTab" > <span className="SelectAyaSurah"> {Arabic}&nbsp;&nbsp;[ {seletedAyaSurah.aya} : {seletedAyaSurah.surah} ]</span> <br/><span style={{fontFamily:font}} className="SelectAyaSurah"> {Translator.s2 !== 'Transliteration' ? Trans : <UnsafeComponent html={Trans} /> }&nbsp;&nbsp;[ {seletedAyaSurah.aya} : {seletedAyaSurah.surah} ]</span> </p> : <p className="NotesTab" > <span className="SelectAyaSurah"> {Arabic}&nbsp;&nbsp;[ {seletedAyaSurah.aya} : {seletedAyaSurah.surah} ]</span> <br/><span style={{fontFamily:font}} className="SelectAyaSurah"> {Translator.s2 !== 'Transliteration' ? Trans : <UnsafeComponent html={Trans} /> }&nbsp;&nbsp;[ {seletedAyaSurah.surah} : {seletedAyaSurah.aya} ]</span> </p>} <Divider dashed /></>} */}
        <br />
            <ul style={{direction:  `rtl` }}>
          { NotesSuraOfAya.length >0 ?
          NotesSuraOfAya.map((note, index) => (
              <li key={note.n100} style={{listStyleType:`arabic-indic`,textAlign:`initial`,fontSize: `20px`,fontFamily:font}}>{note.notes}</li>
          )) : <Empty imageStyle={{height:50}}  description={<span>No Notes Found</span>}></Empty>
          }
          </ul>
          </div>
          </Container>
        </Tab>
        <Tab eventKey="design" title="Design">
        <Container fluid>
          <div style={{marginTop:`20px`}} id="borderimg1">
          { myData && <div className="suraHeaderFrame"><div className="suraHeaderText" >{suraName}</div></div> }
            {/* { myData && <> {Translator.s4 === 'R' ? <p className="NotesTab" > <span className="SelectAyaSurah"> {Arabic}&nbsp;&nbsp;[ {seletedAyaSurah.aya} : {seletedAyaSurah.surah} ]</span> <br/><span style={{fontFamily:font}} className="SelectAyaSurah"> {Translator.s2 !== 'Transliteration' ? Trans : <UnsafeComponent html={Trans} /> }&nbsp;&nbsp;[ {seletedAyaSurah.aya} : {seletedAyaSurah.surah} ]</span> </p> : <p className="NotesTab" > <span className="SelectAyaSurah"> {Arabic}&nbsp;&nbsp;[ {seletedAyaSurah.aya} : {seletedAyaSurah.surah} ]</span> <br/><span style={{fontFamily:font}} className="SelectAyaSurah"> {Translator.s2 !== 'Transliteration' ? Trans : <UnsafeComponent html={Trans} /> }&nbsp;&nbsp;[ {seletedAyaSurah.surah} : {seletedAyaSurah.aya} ]</span> </p>} <Divider dashed /></>} */}
            <br />
            <Image.PreviewGroup>
            {myData ?  myData.map((imgs, index) => (
              Object.entries(imgs.source).map(([key, value]) => [
                <Image id="design" key={key} onError={ErrorImg} src={value.default} />  
              ])           
            ))  : <Image id="design" key={key} onError={ErrorImg} src={`../../assets/Images/noimage.png`} />  
            }
            </Image.PreviewGroup>
          </div> 
          </Container>   
        </Tab>
      </Tabs>
  );
}

export default Middiv;
