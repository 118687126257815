//All Actions for Reducer

//Radio For Translation Tab
function Radio(value) {
  return {
    type: "Fixed",
    payload: value,
  };
}

// Input value font size for dispalyOption Tab
function displayoption (value){
  return {
    type: "Error",
    payload: value
  };
}

//Check box for AlignData for displayOption Tab
function AlignCheck (value){
  return {
    type: "sura",
    payload: value
  };
}

// Surah Load on Select 
function SelectCombo (sura){
return{
type: "Sura",
payload:sura
};
}

function SuraAya (ssa){
  return{
  type: "SuraAya",
  payload:ssa
  };
  }

function SurahData (totalSurah){
    return{
    type: "Sura_Data",
    payload:totalSurah
    };
    }
function LanguagesData (language){
      return{
      type: "language",
      payload:language
      };
      }
function TranslatorData (translator){
    return{
    type: "translator",
    payload:translator
    };
    }
 function NOofSura (totalsura){
      return{
      type: "totalsura",
      payload:totalsura
      };
      }
 function FontFamily (Font_Family){
        return{
        type: "FontFamily",
        payload:Font_Family
        };
        }


export {displayoption,Radio,AlignCheck,SelectCombo,SuraAya,SurahData,LanguagesData,TranslatorData,NOofSura,FontFamily}
